// @use 'assets/scss/fonts';
@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  padding: 0;
  color: var(--dark-primary-text);
  font-family: 'Open Sans', sans-serif;
  overflow: overlay;
  background: var(--color-primary-background);
}

