/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-display: swap;
  font-style: normal;
  font-weight: 400;

  src: url('../fonts/Material-Icons.woff2') format('woff2');
}

@font-face {
  font-family: MepcoText;
  font-display: swap;

  src: url('../fonts/M025096D.ttf');
}

@font-face {
  font-family: MicrogrammaD-BoldExte;
  font-display: swap;
  src: url('../fonts/M025096D.ttf') format('truetype');
}

@font-face {
  font-family: PTSans-Narrow;
  font-display: swap;
  src: url('../fonts/PTSansNarrow-Regular.ttf') format('truetype');
}

@font-face {
  font-family: PTSans-NarrowBold;
  font-display: swap;
  src: url('../fonts/PTSansNarrow-Bold.ttf') format('truetype');
}

@font-face {
  font-family: Roboto-Thin;
  font-display: swap;
  src: url('../fonts/Roboto-Thin.ttf') format('truetype');
}

@font-face {
  font-family: Roboto-Bold;
  font-display: swap;
  src: url('../fonts/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: Roboto-Light;
  font-display: swap;
  src: url('../fonts/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: Roboto-Medium;
  font-display: swap;
  src: url('../fonts/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: Roboto-Regular;
  font-display: swap;
  src: url('../fonts/Roboto-Regular.ttf') format('truetype');
}
