@use '@angular/material' as mat;

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

/*********** Mat Form Field  ***********/
// Custom classes to define form fields size through theming
.mat-mdc-form-field-flex {
  @include mat.form-field-density(-1);
}

.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
  background-color: var(--gray-100);
  border-radius: 5px;
  border: 1px solid var(--color-neutral) !important;
  padding-right: 12px;
  height: 55px;
  align-items: center;
  .mat-icon {
    color: var(--color-neutral);
  }
}

.mat-mdc-form-field-flex {
  --mat-form-field-container-height: 49px !important;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 14px;
  --mat-form-field-filled-with-label-container-padding-top: 14px;
  --mat-form-field-filled-with-label-container-padding-bottom: 0px;
  font-size: 14px;
}

.mat-mdc-form-field-icon-prefix,
.mat-mdc-form-field-icon-suffix {
  padding: 0 !important;
  > .mat-icon {
    padding: 0 !important;
    height: 20px !important;
    font-size: 150% !important;
    line-height: 1.125 !important;
  }
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
  height: 34px !important;
}

mat-form-field.mat-form-field-appearance-fill .mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
  font-size: 12px !important;
}

mat-form-field.mat-form-field-appearance-outline .mat-mdc-form-field-error-wrapper {
  font-size: 12px !important;
  margin-left: -8px !important;
}

// show label for more dense inputs as well
.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: inline !important;
  font-size: 14px;
}

// Adjust the Floating label height
.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: calc(var(--mat-form-field-container-height) / 1.4) !important;
}

// Fix conflicts with mat-form-field
.mat-mdc-form-field .mdc-notched-outline__notch {
  border-right-style: hidden;
}

// Adjust the Floating label height when is focused
.mdc-text-field--filled .mdc-floating-label--float-above {
  transform: translateY(-180%) scale(0.75) !important;
}

// Manipulate initial background color
.mdc-text-field--filled {
  background: transparent !important;
  padding: 0 !important;
}

/** Mat Dialog **/
.mat-mdc-dialog-container .mat-mdc-dialog-content {
  padding: 1.5rem 0 !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  padding: 16px 24px;
  .mat-mdc-dialog-content {
    padding: 1rem;
  }
  .mat-mdc-dialog-title {
    @apply flex justify-between items-center;
  }

  .mat-mdc-dialog-title {
    font-size: 18px;
    border-bottom: 1px solid var(--color-neutral) !important;
    margin-bottom: 5px !important;
    padding: 0px !important;
    .mat-icon {
      height: 35px !important;
    }
    span {
      margin-right: auto;
    }
  }
  .mat-mdc-dialog-actions {
    padding: 10px 0px 0px;
    border-top: none;
  }
}

.mat-mdc-dialog-container .mdc-dialog__content {
  font-size: 14px !important;
}

// Manipulate background color on hover and focus
.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

/*********** Mat Select  ***********/
.mat-mdc-option {
  --mat-option-selected-state-label-text-color: var(--primary-500) !important;
}
// Removed checkbox for the selected option
.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  display: none;
}

/*********** Navbar ************/
.mat-mdc-tab-link .mdc-tab__text-label {
  min-width: auto !important;
}

.mat-mdc-tab-link {
  letter-spacing: normal !important;
}

/*********** Input Date ***********/

mat-datepicker-toggle {
  --mat-datepicker-toggle-active-state-icon-color: var(--primary-500) !important;

  // Calendar icon size
  .mdc-icon-button.mat-mdc-icon-button.mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: 40px !important;
    --mdc-icon-button-icon-size: 20px !important;
    --mat-icon-button-state-layer-color: transparent !important;
    --mat-icon-button-ripple-color: transparent !important;
  }
}
// Calendar dialogs colors
.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-hover-state-background-color: var(--primary-100) !important;
  --mat-datepicker-calendar-date-focus-state-background-color: var(--primary-100) !important;
  --mat-datepicker-calendar-date-selected-state-background-color: var(--primary-500) !important;
}

/*********** Snackbar***********/

.mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: var(--primary-50);
  --mdc-snackbar-supporting-text-color: var(--primary-50);

  &.snackbar-error {
    --mdc-snackbar-container-color: var(--snackbar-error-background);
    .text-color {
      color: var(--snackbar-error-text);
    }
  }

  &.snackbar-success {
    --mdc-snackbar-container-color: var(--snackbar-success-background);
    .text-color {
      color: var(--snackbar-success-text);
    }
  }

  &.snackbar-info {
    --mdc-snackbar-container-color: var(--snackbar-info-background);
    .text-color {
      color: var(--snackbar-info-text);
    }
  }

  &.snackbar-warning {
    --mdc-snackbar-container-color: var(--snackbar-warning-background);
    .text-color {
      color: var(--snackbar-warning-text);
    }
  }

  &.snackbar-news {
    --mdc-snackbar-container-color: var(--warn-700);
  }
}

/*********** Spinner ***********/
.mat-mdc-progress-spinner circle,
.mat-mdc-spinner circle {
  stroke: var(--accent-500);
}

/******** Icon ***********/
.mat-icon {
  vertical-align: middle;
}
.mdc-icon-button.mat-mdc-icon-button.mat-mdc-button-base {
  margin-left: auto !important;
}

.mat-mdc-button > .mat-icon,
.mat-mdc-unelevated-button > .mat-icon,
.mat-mdc-raised-button > .mat-icon,
.mat-mdc-outlined-button > .mat-icon {
  transform: scale(1.5) !important;
}

/****** Tab Nav *******/

/*********** Mat tab ***********/
.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab {
  flex-grow: 0 !important;
}

.mat-mdc-tab-group.mat-accent,
.mat-mdc-tab-nav-bar.mat-accent {
  --mat-tab-header-active-label-text-color: var(--dark-primary-text) !important;
  --mat-tab-header-active-hover-label-text-color: var(--dark-primary-text) !important;
  --mat-tab-header-active-focus-label-text-color: var(--dark-primary-text) !important;
  --mat-tab-header-active-ripple-color: var(--color-neutral-light) !important;
  --mat-tab-header-inactive-ripple-color: var(--color-neutral-light) !important;
  --mat-tab-header-inactive-label-text-color: var(--color-dark) !important;
}

.mat-mdc-tab-header {
  --mat-tab-header-divider-color: var(--dark-dividers);
  --mat-tab-header-divider-height: 1px;
}

.mat-mdc-tab {
  letter-spacing: normal !important;
}

.mat-mdc-raised-button {
  letter-spacing: normal !important;
}

/** Mat card **/
.mat-mdc-card-content {
  padding: 0 !important;
}

.mat-mdc-card {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-mdc-card-subtitle {
  margin: 16px;
  line-height: 20px;
}

.mat-mdc-card-title {
  font-size: 18px !important;
  font-family: Roboto-Regular !important;
  margin-bottom: 16px !important;
  color: var(--color-darkest) !important;
}

.mat-mdc-select {
  display: grid !important;
  height: 34px !important;
  font-size: 14px !important;
}

.mat-mdc-option {
  font-size: 14px !important;
}

/*********** Stepper ***********/
.mat-step-label.mat-step-label-selected {
  --mat-stepper-header-selected-state-label-text-size: 14px !important;
}

.mat-horizontal-content-container {
  padding: 0 !important;
}
